<template>
  <v-layout column>
    <DefaultSubtitle class="my-3">Ciąża w trakcie</DefaultSubtitle>
    <HTMLTextArea
      class="mb-3"
      placeholder="Wpisz..."
      :value.sync="value.reason"
      :disabled="disabled"
      dense
      hideLabel
    ></HTMLTextArea>
    <LabelDateField
      label="Planowana data porodu"
      placeholder="Wybierz..."
      :value.sync="value.date"
      :disabled="disabled"
      hide-details
      noMargins
    ></LabelDateField>
  </v-layout>
</template>

<script>
export default {
  props: {
    value: {},
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DefaultSubtitle: () => import("@/components/text/DefaultSubtitle"),
    HTMLTextArea: () => import("@/components/inputs/HTMLTextArea"),
    LabelDateField: () => import("@/components/LabelDateField"),
    PregnancyStatusMenu: () =>
      import("@/components/menus/patientcard/PregnancyStatusMenu"),
  },
  methods: {
    onChangeKind(kind) {
      this.$emit("change", kind);
    },
  },
};
</script>